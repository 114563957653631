<template>
  <div/>
</template>

<script>
import StorageService from '../../services/storage.service';

export default {
  data() {
    return {
      message: "Please wait"
    }
  },
  created() {
    if (this.$route.params.referralid) {
      StorageService.saveData("referral_id", this.$route.params.referralid);
      this.$router.replace("/");
    } else {
      this.message = "Invalid Link";
    }
  }
}
</script>